import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ApiInterceptor } from './api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { InspectionDataFormComponent } from './page/inspection/inspection-data-form/inspection-data-form.component';
import { InspectionTableComponent } from './page/inspection/inspection-table/inspection-table.component';
// import { WorkOrderListPageModule } from './page/work-order-list/work-order-list.module';
import { CommonAppService } from './services/app.service';
import { SharedModule } from './shared/module/shared-module';
import { TranslocoRootModule } from './transloco-root.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    UnauthorizedUserComponent,
    InspectionTableComponent,
    InspectionDataFormComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule.forRoot({ fgsColor: "red" ,bgsSize:60, pbColor: "red",textPosition:"center-center",text: "Processing..."}),
  ],
  providers: [
    Platform,
    CommonAppService,
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    { provide: TRANSLOCO_SCOPE, useValue: '' }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
